<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const store = useStore()

const items = computed(() => {
  return store.getters['checkout/items']
})
const price = computed(() => {
  return store.getters['checkout/price']
})
const additionalOptions = computed(() => store.getters['checkout/additionalOptions'])

const productPrice = computed(() => {
  if (price.value) {
    return price.value.discounted > 0 ? price.value.discounted : price.value.total
  }
})
const total = computed(() => {
  let amount = 0
  if (price.value) {
    amount += productPrice.value
    amount += price.value.delivery
  }
  additionalOptions.value.forEach(i => {
    if (i.selected) {
      amount += i.price
    }
  })
  return amount
})
function calcTaxPrice (amount) {
  const tax = 19
  return amount / (tax + 100) * (100 - tax)
}
</script>

<template>
  <h4 class="text-center">Bitte überprüfen Sie Ihre Angaben sorgfältig, bevor Sie die Bestellung aufgeben.</h4>
<table class="table table-sm table-borderless">
  <thead v-if="items" class="table-secondary">
  <tr>
    <th>Produkt</th>
    <th>Preis</th>
    <th>Menge</th>
    <th>Gesamtpreis</th>
  </tr>
  </thead>
  <tbody>
  <template v-for="(item, index) in items" :key="index">
    <tr>
      <td class="fw-bold">{{ item.name }}</td>
      <td></td>
      <td>1</td>
      <td class="text-end">
        <template v-if="item.price.discounted > 0">
          {{ $filters.currency(item.price.discounted) }} (inkl MwSt.)<br>
          <span class="small-size text-secondary">{{ $filters.currency(calcTaxPrice(item.price.discounted)) }} (exkl. MwSt.)</span>
        </template>
        <template v-else>
          {{ $filters.currency(item.price.total) }} (inkl MwSt.)<br>
          <span class="small-size text-secondary">{{ $filters.currency(calcTaxPrice(item.price.total)) }} (exkl. MwSt.)</span>
        </template>
      </td>
    </tr>
    <tr v-for="(step, idx) in item.steps" :key="idx">
      <td>{{ step.name }}: {{ step.value }}</td>
      <td class="text-start">
        <template v-if="step.option && step.option[0]">
          <div v-if="step.option[0].discounted">
            <div class="total-price total-price--discount">{{ $filters.currency(step.option[0].discounted) }}</div>
            <div class="total-price total-price--old mt-1">{{ $filters.currency(step.option[0].total) }}</div>
          </div>
          <div v-else class="total-price total-price mt-1">
            <span v-if="step.option[0].total > 0">
              {{ $filters.currency(step.option[0].total) }}
            </span>
          </div>
        </template>
      </td>
      <td></td>
      <td></td>
    </tr>
  </template>
  </tbody>
  <tfoot class="border-top">
  <tr>
    <td class="text-end" colspan="3">Preis der Produkte</td>
    <td class="text-end">{{ $filters.currency(productPrice) }}</td>
  </tr>
    <template v-for="(item, idx) in additionalOptions" :key="idx">
      <tr v-if="item.selected">
        <td class="text-end" colspan="3">{{ item.label }}</td>
        <td class="text-end">
          {{ $filters.currency(item.price) }}
        </td>
      </tr>
    </template>
  <tr>
    <td class="text-end" colspan="3">Versandkosten</td>
    <td class="text-end">
      {{ $filters.currency(price.delivery) }}
    </td>
  </tr>
  <tr>
    <td class="text-end" colspan="3">Summe gesamt</td>
    <td class="text-end fw-bold">
      {{ $filters.currency(total) }}
    </td>
  </tr>
  <tr>
    <td class="text-end" colspan="3">enthaltene MwSt. 19%</td>
    <td class="text-end">
      {{ $filters.currency(productPrice * (19 / 100)) }}
    </td>
  </tr>
  <tr>
    <td class="text-end" colspan="3">Anzahlung 20%</td>
    <td class="text-end" >
      {{ $filters.currency(productPrice * (20 / 100)) }}
    </td>
  </tr>
  </tfoot>
</table>
</template>
